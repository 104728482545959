/* Configuração monster-web-kit-angular */

@import '@techramper/monster-web-kit-core/dist/utils/css/monster.margin.css';
@import '@techramper/monster-web-kit-core/dist/utils/css/monster.padding.css';

:root {
  // BORDER

  --ms-border-width: 2px;
  --ms-border-style: solid;
  --ms-border-color: #e2e8f0;

  // TEXT

  --ms-font-family: 'Inter';
  --ms-font-family-title: 'Inter';
  --ms-text-color: #000000;

  // BACKGROUND

  --ms-background-color: #f9f9fb;
  --ms-background-color-rgb: 229, 229, 229;

  // MS-TABLE

  --ms-table-border-spacing: 0;
  --ms-table-margin-top: 0px;

  --ms-table-header-background-color: #f8fafc;
  --ms-table-header-text-color: #64748b;
  --ms-table-header-padding: 12px 16px;
  --ms-table-header-border-radius: 12px;
  --ms-table-header-font-size: 12px;

  --ms-table-row-background-color: transparent;
  --ms-table-row-hover-color: #eeeff1;
  --ms-table-row-select-color: #cbd5e1;
  --ms-table-row-select-border-color: #bac0cc;
  --ms-table-row-disabled-text-color: #b8c1ce;
  --ms-table-row-height: 60px;
  --ms-table-row-padding: 0px 16px;
  --ms-table-row-border-radius: 0px;
  --ms-table-row-font-size: 14px;

  // MS-TOGGLE

  --ms-toggle-disabled-background-color: #f0f0f0;

  // MS-BUTTON

  --ms-button-disabled-background-color: #f0f0f0;
  --ms-button-disabled-border-color: #bfbfbf;
  --ms-button-disabled-color: #bfbfbf;

  // MS-INPUT

  --ms-input-label-color: #262626;
  --ms-input-border-color: #e2e8f0;
  --ms-input-placeholder-color: #8c8c8c;
  --ms-input-disabled-color: #f0f0f0;
  --ms-input-input-error-color: #e01e5a;

  // COMPONENT MS-TITLE

  --ms-title-font-size-1: 20px;
  --ms-title-font-size-2: 20px;
  --ms-title-font-size-3: 16px;
  --ms-title-font-size-4: 16px;
  --ms-title-font-size-5: 14px;
  --ms-title-font-size-6: 14px;

  --ms-title-font-weight-1: 700;
  --ms-title-font-weight-2: 400;
  --ms-title-font-weight-3: 700;
  --ms-title-font-weight-4: 400;
  --ms-title-font-weight-5: 700;
  --ms-title-font-weight-6: 400;

  // MS-ALERT

  --ms-alert-info-border-color: #3a96c6;
  --ms-alert-warning-border-color: #f9d04a;
  --ms-alert-success-border-color: #87c441;
  --ms-alert-error-border-color: #e01e5a;

  --ms-alert-info-background-color: #e6f7ff;
  --ms-alert-warning-background-color: #fffbe6;
  --ms-alert-success-background-color: #f6ffed;
  --ms-alert-error-background-color: #fff2f0;

  --ms-alert-info-text-color: #262626;
  --ms-alert-warning-text-color: #262626;
  --ms-alert-success-text-color: #262626;
  --ms-alert-error-text-color: #262626;

  // MS-TAG

  --ms-tag-green-background-color: #e4fbee;
  --ms-tag-green-border-color: #aff3cb;
  --ms-tag-green-text-color: #0d592c;
  --ms-tag-green-icon-color: #1bb258;

  --ms-tag-orange-background-color: #fdf5e3;
  --ms-tag-orange-border-color: #fae9c2;
  --ms-tag-orange-text-color: #503a06;
  --ms-tag-orange-icon-color: #efb734;

  --ms-tag-red-background-color: #fbe9e9;
  --ms-tag-red-border-color: #f6cbcb;
  --ms-tag-red-text-color: #4f0d0d;
  --ms-tag-red-icon-color: #e04747;

  --ms-tag-gray-background-color: #e7eaee;
  --ms-tag-gray-border-color: #b9c0cc;
  --ms-tag-gray-text-color: #262626;
  --ms-tag-gray-icon-color: #7a7a7a;

  --ms-tag-disabled-background-color: #f9f9fb;
  --ms-tag-disabled-border-color: #dddde9;
  --ms-tag-disabled-text-color: #bfbfbf;
  --ms-tag-disabled-icon-color: #bfbfbf;

  // MS-SEARCH-MULTIPLE

  --ms-search-multiple-tag-background-color: #e2e8f0;
  --ms-search-multiple-tag-text-color: #374151;
  --ms-search-multiple-tag-icon-color: #374151;
  --ms-search-multiple-limit-text-color: #bfbfbf;

  // MS-SELECT

  --ms-select-tag-background-color: #e2e8f0;
  --ms-select-tag-text-color: #374151;
  --ms-select-tag-icon-color: #374151;
  --ms-select-limit-text-color: #bfbfbf;
  --ms-select-count-text-color: #64748b;

  // MS-PaGINATION

  --ms-pagination-button-padding: 4px;
  --ms-pagination-button-border-radius: 6px;
  --ms-pagination-button-width: 28px;
  --ms-pagination-button-height: 24px;
  --ms-pagination-icon-size: 12px;

  // MS-BUTTON
  // MS-CARD
  // MS-ICON
  // MS-CHECKBOX
  // MS-LOADING
  // MS-DROPDOWN
  // MS-NAVBAR
  // MS-CALENDAR
  // MS-DATEPICKER
  // MS-TOGGLE

  --ms-color-primary: #1e3a8a;
  --ms-color-primary-rgb: 30, 58, 138;
  --ms-color-primary-contrast: #fff;
  --ms-color-primary-contrast-rgb: 255, 255, 255;
  --ms-color-primary-hover: #2f4b9a;
  --ms-color-primary-active: #405bb2;
  --ms-color-primary-hover-inverted: #e3e6eb;
  --ms-color-primary-active-inverted: #c7cdd7;

  --ms-color-secondary: #0369a1;
  --ms-color-secondary-rgb: 3, 105, 161;
  --ms-color-secondary-contrast: #fff;
  --ms-color-secondary-contrast-rgb: 255, 255, 255;
  --ms-color-secondary-hover: #0477b8;
  --ms-color-secondary-active: #0484cf;
  --ms-color-secondary-hover-inverted: #e8f8ff;
  --ms-color-secondary-active-inverted: #d0f0ff;

  --ms-color-success: #2dd36f;
  --ms-color-success-rgb: 45, 211, 111;
  --ms-color-success-contrast: #fff;
  --ms-color-success-contrast-rgb: 255, 255, 255;
  --ms-color-success-hover: #46d880;
  --ms-color-success-active: #5fde92;
  --ms-color-success-hover-inverted: #e6faee;
  --ms-color-success-active-inverted: #cdf4dc;

  --ms-color-warning: #f9d04a;
  --ms-color-warning-rgb: 249, 208, 74;
  --ms-color-warning-contrast: #fff;
  --ms-color-warning-contrast-rgb: 255, 255, 255;
  --ms-color-warning-hover: #ffcb27;
  --ms-color-warning-active: #ffd244;
  --ms-color-warning-hover-inverted: #fff8e1;
  --ms-color-warning-active-inverted: #fff1c4;

  --ms-color-danger: #e01e5a;
  --ms-color-danger-rgb: 235, 68, 90;
  --ms-color-danger-contrast: #fff;
  --ms-color-danger-contrast-rgb: 255, 255, 255;
  --ms-color-danger-hover: #ed5a6e;
  --ms-color-danger-active: #f07182;
  --ms-color-danger-hover-inverted: #fde9eb;
  --ms-color-danger-active-inverted: #fad2d7;

  --ms-color-dark: #000000;
  --ms-color-dark-rgb: 0, 0, 0;
  --ms-color-dark-contrast: #fff;
  --ms-color-dark-contrast-rgb: 255, 255, 255;
  --ms-color-dark-hover: #1f1f1f;
  --ms-color-dark-active: #3d3d3d;
  --ms-color-dark-hover-inverted: #e0e0e0;
  --ms-color-dark-active-inverted: #c2c2c2;

  --ms-color-medium: #bfbfbf;
  --ms-color-medium-rgb: 191, 191, 191;
  --ms-color-medium-contrast: #fff;
  --ms-color-medium-contrast-rgb: 255, 255, 255;
  --ms-color-medium-hover: #9fa1a8;
  --ms-color-medium-active: #acaeb4;
  --ms-color-medium-hover-inverted: #f2f2f3;
  --ms-color-medium-active-inverted: #e5e5e7;

  --ms-color-light: #ffffff;
  --ms-color-light-rgb: 255, 255, 255;
  --ms-color-light-contrast: #000;
  --ms-color-light-contrast-rgb: 0, 0, 0;
  --ms-color-light-hover: #f3f4f6;
  --ms-color-light-active: #e7eaee;
  --ms-color-light-hover-inverted: #e0e0e0;
  --ms-color-light-active-inverted: #c2c2c2;

  --ms-color-grayscale: #e7eaee;
  --ms-color-grayscale-rgb: 231, 234, 238;
  --ms-color-grayscale-contrast: #000;
  --ms-color-grayscale-contrast-rgb: 0, 0, 0;
  --ms-color-grayscale-hover: #eaedf0;
  --ms-color-grayscale-active: #edeff2;
  --ms-color-grayscale-hover-inverted: #fcfcfd;
  --ms-color-grayscale-active-inverted: #f9fafb;
}
