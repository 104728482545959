@import 'fonts.scss';
@import 'monster.scss';
@import 'scrollbar.scss';

// Body

body {
  background-color: #f9f9f9;
  margin: 0px;
  color: var(--ms-text-color);
}

body,
app-root {
  width: 100vw;
  height: 100vh;
  color: var(--ms-text-color);
}
