@supports (scrollbar-width: auto) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #dbdbdb #f2f2f2;
  }
}

@supports selector(::-webkit-scrollbar) {
  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 0px;
    border: 0px dashed #ffffff;
  }
}
