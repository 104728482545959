// Fonte

@import url('https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&display=swap');

// Redefinir css para todos os componentes HTML

* {
  font-family: 'Inter';
}

// title

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter';
}
